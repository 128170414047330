import React from "react";
import Apps from "../containers/visuella/Apps";

const AppsPage = () => {
  return (
    <>
      <Apps isLatest={false} />
    </>
  );
};
export default AppsPage;
